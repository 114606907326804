<template>
  <div class="main-stat">
    <div>
      <div class="class-marks">
        <div name="SettingsLoadBlock" id="scaleHover">
          <div>
            <div class="class-mark" style="padding-bottom: 34.05px">
              <div class="class-mark-change">
                <div>
                  <b>Сформировать отчет на:</b>
                  <select
                    name=""
                    v-model="globalOption"
                    class="form-select"
                    id=""
                    @change="changeGlobalOption(globalOption)"
                  >
                    <option>Отсутствующие</option>
                    <option>Питание</option>
                    <option>Питание 1 смена</option>
                    <option>Питание 2 смена</option>
                  </select>
                </div>
                <div>
                  <input
                    type="date"
                    id="Date"
                    class="form-control"
                    required
                    v-model="sDates.date"
                    @change="initialization()"
                  />
                </div>

                <div>
                  <button @click="initialization()" class="btn btn-success">
                    Обновить
                  </button>
                </div>
                <hr style="margin: 10px 0" />
                <div class="class-mark-change">
                  <b> Загрузить по диапазону</b>
                  <div class="flex-food-main">
                    <div class="flex-food-main">
                      <div style="width: 30px; padding-top: 6px">От</div>
                      <div class="flex" style="width: 220px">
                        <input
                          type="date"
                          v-model="dateFrom"
                          id="DateFrom"
                          required
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="flex-food-main">
                      <div style="width: 30px; padding-top: 6px">До</div>
                      <div class="flex" style="width: 220px">
                        <input
                          type="date"
                          v-model="dateBefore"
                          id="DateBefore"
                          required
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-food">
                  <div style="display: flex; align-items: stretch">
                    <div>
                      <b> Класс</b>
                      <select
                        id="class"
                        class="form-select"
                        v-model="selectedClassID"
                        @change="changeClass()"
                      >
                        <option
                          v-for="user in classList"
                          :key="user.className"
                          v-bind:value="user"
                        >
                          {{ user.className }}
                        </option>
                      </select>
                    </div>
                    <div style="width: 200px">
                      <b>Ученик</b>
                      <select class="form-select" v-model="selectedStudentID">
                        <!-- @change="changeStudent()" -->
                        <option
                          v-for="user in studentsListByClassID"
                          :key="user._id"
                          v-bind:value="user"
                        >
                          {{ user.FirstName }} {{ user.LastName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="flex-food">
                    <button
                      @click="initializationByClass()"
                      class="btn btn-success"
                    >
                      Загрузить (класс\ученик)
                    </button>
                  </div>
                  <div class="flex-food-main">
                    <div
                      class="flex-food"
                      style="width: 200px; padding: 2px 0 0 5px"
                    >
                      <button
                        @click="initializationByRange()"
                        class="btn btn-outline-success"
                      >
                        Загрузить (вся школа)
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div name="fastStatBlock">
          <div>
            <div class="class-mark">
              <v-progress-linear
                style="margin: 4px 4px 0 4px"
                v-model="progress"
              ></v-progress-linear>

              <div v-if="progress === 100">
                <ul id="ul-stat">
                  <li>
                    Всего учеников:
                    <b style="padding: 0 5px 0 5px">
                      {{ studentsList.length }} (на
                      {{ new Date().toLocaleDateString() }})</b
                    >
                  </li>
                  <li>Выбранный день: {{ sDates.date }}</li>

                  <li
                    v-if="marks.length === studentsList.length"
                    style="color: green"
                    class="LI-class"
                  >
                    Данные отправлены на: {{ marks.length }}
                  </li>
                  <li
                    v-if="marks.length != studentsList.length"
                    style="color: red"
                  >
                    Данные отправлены на: {{ marks.length }}
                  </li>
                  <li>
                    Отсутствует: {{ marksPrint.length }} ({{
                      reversedMessage(marksPrint.length)
                    }}%)
                  </li>
                  <div
                    v-if="selectedStudentID != '' && showStudentsInfo"
                    style="color: purple"
                  >
                    Данные на ученика:
                    <br />
                    <b
                      >{{ selectedStudentID.FirstName }}
                      {{ selectedStudentID.LastName }}</b
                    >
                    <br />
                  </div>

                  <div style="color: purple">
                    на диапазон дат: <br />
                    {{ dateFrom }} - {{ dateBefore }}
                  </div>
                </ul>
                <div>
                  <table
                    id="ul-stat3"
                    style="margin-bottom: 10px"
                    class="reciept-table"
                  >
                    <thead>
                      <th style="max-width: 220px">Причина</th>
                      <th>Количество</th>
                    </thead>
                    <tbody v-for="caus in countAll" :key="caus.causes">
                      <td style="max-width: 220px">
                        <tr>
                          {{
                            caus.causes
                          }}
                        </tr>
                      </td>
                      <td>
                        <tr>
                          {{
                            caus.count
                          }}
                          ({{
                            reversedMessage(caus.count)
                          }}%)
                        </tr>
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <Preloader ref="Preloader" />
              </div>
            </div>
          </div>
        </div>

        <div name="statusClass">
          <div>
            <div>
              <div class="class-mark">
                <section class="class-mark-shift">
                  <header>1 смена</header>
                </section>
                <transition-group class="class-mark-noBoard" name="list">
                  <div v-for="value in classList" :key="value.className">
                    <div
                      v-if="value.shift === '1 смена'"
                      :class="{
                        'class-mark-item-BAD': !value.created,
                        'class-mark-item-noPortion': value.sumPortionFood == 0,
                        'class-mark-item-change': value.change,
                        'class-mark-item-ok': value.created,
                        'class-mark-item-differ':
                          value.sumMark != value.sumStudent,
                      }"
                      :title="
                        value.className +
                        '\n' +
                        'Отправлено на: ' +
                        value.sumMark +
                        '\n' +
                        'Детей в классе: ' +
                        value.sumStudent +
                        '\n' +
                        'Порций: ' +
                        value.sumPortionFood
                      "
                    >
                      {{ value.className }}
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>

            <div>
              <div class="class-mark">
                <section class="class-mark-shift">
                  <header>2 смена</header>
                </section>

                <transition-group class="class-mark-noBoard" name="list">
                  <div v-for="value in classList" :key="value.className">
                    <div
                      v-if="value.shift === '2 смена'"
                      :class="{
                        'class-mark-item-change': value.change,
                        'class-mark-item-noPortion': value.sumPortionFood == 0,
                        'class-mark-item-ok': value.created,
                        'class-mark-item-differ':
                          value.sumMark != value.sumStudent,
                        'class-mark-item-BAD': !value.created,
                      }"
                      :title="
                        value.className +
                        '\n' +
                        'Отправлено на: ' +
                        value.sumMark +
                        '\n' +
                        'Детей в классе: ' +
                        value.sumStudent +
                        '\n' +
                        'Порций: ' +
                        value.sumPortionFood
                      "
                    >
                      {{ value.className }}
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
            <div>
              <div class="class-mark">
                <ExportSummaryTable
                  :marks="marks"
                  :studentsListProps="studentsList"
                  :classList="classList"
                  :selectedClassID="selectedClassID"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="class-mark" style="width: auto">
            <div>
              <button @click="help()" class="btn btn-warning">Помощь</button>

              <button
                @click="showModalGeneralSummary()"
                class="btn btn-outline-secondary"
              >
                Дети по категориям
              </button>
              <button
                target="_blank"
                @click="newPrintStat()"
                class="btn btn-secondary"
                :class="{
                  'btn btn-danger': ErrorTableEat.check,
                  'btn btn-secondary': !ErrorTableEat.check,
                }"
                :title="
                  'Перед печатью проверить: \nПитающихся:' +
                  ErrorTableEat.count +
                  '\nВ таблице:' +
                  ErrorTableEat.countTable
                "
              >
                Печать
              </button>
              <br />
              <button
                type="button"
                class="btn btn-primary"
                @click="showStatVisualF()"
                aria-label="Close modal"
                style="color: white"
              >
                Визуализация данных (тест)
              </button>

              <br />
            </div>
          </div>
          <div class="class-mark" style="width: auto">
            <div>
              <button
                target="_blank"
                @click="getClassListCount()"
                class="btn btn-outline-secondary"
                style="margin: 5px"
              >
                Количество детей по классам
              </button>
              <v-btn
                href="https://missing.actsch24.ru/"
                target="_blank"
                style="color: white; background-color: #2c86fc"
                text
              >
                Отсутсвующие в разрезе на смены и уровни образования &nbsp;
                <svg
                  width="14px"
                  height="14px"
                  viewBox="0 0 24.00 24.00"
                  id="meteor-icon-kit__regular-external-link"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#000000"
                  stroke-width="0.00024000000000000003"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22 3.41421L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L20.5858 2H18C17.4477 2 17 1.55228 17 1C17 0.447715 17.4477 0 18 0H23C23.5523 0 24 0.447715 24 1V6C24 6.55228 23.5523 7 23 7C22.4477 7 22 6.55228 22 6V3.41421ZM12.9838 3C13.536 3 13.9838 3.44772 13.9838 4C13.9838 4.55228 13.536 5 12.9838 5H3C2.44772 5 2 5.44772 2 6V21C2 21.5523 2.44772 22 3 22H18.0011C18.5534 22 19.0011 21.5523 19.0011 20.9973L18.9734 11.0028C18.9719 10.4505 19.4184 10.0015 19.9706 10C20.5229 9.99847 20.9719 10.4449 20.9734 10.9972L21.0011 21C21.0011 22.6569 19.658 24 18.0011 24H3C1.34315 24 0 22.6569 0 21V6C0 4.34315 1.34315 3 3 3H12.9838Z"
                      fill="white"
                    ></path>
                  </g>
                </svg>
              </v-btn>
              <table class="table" id="classCountTable" hidden>
                <thead>
                  <tr>
                    <td colspan="7">
                      <div>
                        <b>1 смена</b> - Присутствует:
                        {{
                          missingCount.FirstShiftAll - missingCount.FirstShift
                        }}; Отсутствует:
                        {{ missingCount.FirstShift }}
                      </div>
                      <div>
                        <b>2 смена</b> - Присутствует:
                        {{
                          missingCount.SecondShiftAll -
                          missingCount.SecondShift
                        }}; Отсутствует:
                        {{ missingCount.SecondShift }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Класс</th>
                    <th>Смена</th>
                    <th>Количество детей</th>
                    <th>Мальчики</th>
                    <th>Девочки</th>
                    <th>Отсутствует</th>
                    <th>Присутствует</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(clas, index) in classListCount" :key="index">
                    <td>{{ clas.className }}</td>
                    <td>{{ clas.shift }}</td>
                    <td>{{ clas.count }}</td>
                    <td>{{ clas.male }}</td>
                    <td>{{ clas.female }}</td>
                    <td>{{ clas.missing }}</td>
                    <td>{{ clas.count - clas.missing }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div style="margin-top: 10p" class="class-marks">
        <div style="max-width: 60vw">
          <div class="class-mark" style="width: auto">
            <TablePortionByDateAndClass
              :classList="classList"
              :marks="marks"
            ></TablePortionByDateAndClass>
          </div>
        </div>
        <div class="class-marks-item">
          <div class="class-mark">
            <div v-show="!readyGlobalReport"><Preloader /></div>

            <GlobalReportEatingVue
              ref="GlobalReportEatingVue "
              :classListAll="classListAll"
              :sCategory="sCategory"
              :studentsList="studentsList"
              @ready="readyGlobalReportFunc"
            />
          </div>
        </div>
        <div style="max-width: 90vw">
          <div class="class-mark" style="width: auto">
            <TableAttendanceDataSummary
              v-show="true"
              :classList="classList"
              :marksPrint="marksPrint"
              :marks="marks"
              :studentsList="studentsList"
              :sDates="sDates"
              :selectedClassID="selectedClassID"
              :selectedClass="selectedClass"
            />
          </div>
        </div>
      </div>
      <hr />
      <button
        v-if="
          globalOption === 'Отсутствующие' ||
          globalOption === 'Питание' ||
          globalOption === 'Питание 1 смена' ||
          globalOption === 'Питание 2 смена'
        "
        class="btn btn-outline-success"
        @click="pickTable()"
      >
        Скопировать таблицу для вставки в EXCEL
      </button>
      <button
        v-if="
          globalOption === 'Отсутствующие' ||
          globalOption === 'Питание' ||
          globalOption === 'Питание 1 смена' ||
          globalOption === 'Питание 2 смена'
        "
        target="_blank"
        @click="newPrintStat()"
        class="btn btn-secondary"
        :class="{
          'btn btn-danger': ErrorTableEat.check,
          'btn btn-secondary': !ErrorTableEat.check,
        }"
        :title="
          'Перед печатью проверить: \nПитающихся:' +
          ErrorTableEat.count +
          '\nВ таблице:' +
          ErrorTableEat.countTable
        "
      >
        Печать
      </button>
      <transition name="component-fade" mode="out-in">
        <div v-if="globalOption === 'Отсутствующие'">
          <div class="myTable">
            <v-col>
              <v-card-title>
                <v-text-field
                  class="search-table"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Поиск (введите Класс\Фамилию\Причину\дату)"
                >
                </v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="this.marksPrint"
                :items-per-page="3000"
                :search="search"
                hide-default-footer
                id="table"
                dense
              >
                <template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>
              </v-data-table>
            </v-col>
          </div>
        </div>

        <transition name="component-fade" mode="out-in">
          <div
            v-if="
              globalOption === 'Питание' ||
              globalOption === 'Питание 1 смена' ||
              globalOption === 'Питание 2 смена'
            "
            class="myTable"
          >
            <div class="myTable">
              <transition name="list">
                <v-data-table
                  :headers="headersEat"
                  :items="this.classListAll"
                  :items-per-page="3000"
                  id="table"
                  hide-default-footer
                  dense
                >
                </v-data-table>
              </transition>
            </div>
          </div>
        </transition>
      </transition>
    </div>

    <StatVisual
      ref="StatVisual"
      :marks="marks"
      :studentsList="studentsList"
      :selectedClass="selectedClass"
      :classList="classList"
      :dateFrom="dateFrom"
      :dateBefore="dateBefore"
      v-show="showStatVisual"
      @close="showStatVisualF()"
    />
    <GeneralSummaryVue
      ref="GeneralSummaryVue"
      v-show="showModalGeneralSummaryCheck"
      @close="showModalGeneralSummary()"
      :classList="classList"
      :studentsList="studentsList"
    />
    <!-- <TableSummaryExport ref="TableSummaryExport" /> -->
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
const GeneralSummaryVue = () => import("./GeneralSummary.vue");
const TableAttendanceDataSummary = () =>
  import("./TableAttendanceDataSummary.vue");
const GlobalReportEatingVue = () => import("./GlobalReportEating.vue");
const Preloader = () => import("./Preloader.vue");
const StatVisual = () => import("./StatVisual.vue");
const ExportSummaryTable = () => import("./ExportSummaryTable.vue");
const TablePortionByDateAndClass = () =>
  import("./TablePortionByDateAndClass.vue");

export default {
  components: {
    ExportSummaryTable: ExportSummaryTable,
    StatVisual: StatVisual,
    GeneralSummaryVue: GeneralSummaryVue,
    GlobalReportEatingVue: GlobalReportEatingVue,
    TableAttendanceDataSummary: TableAttendanceDataSummary,
    Preloader: Preloader,
    TablePortionByDateAndClass: TablePortionByDateAndClass,
  },
  props: {
    selectedClass: Object,
  },
  data() {
    return {
      showStatVisual: false,
      readyGlobalReport: false,
      showModalGeneralSummaryCheck: false,
      showStudentsInfo: false,
      search: [],
      dateFrom: [],
      dateBefore: [],
      sCategory: [],
      globalOption: "start",
      headers: [
        {
          value: "index",
          text: "#",
          width: "30px",
        },
        {
          text: "Класс",
          value: "className",
          sortable: true,
        },
        {
          text: "Фамилия",
          value: "FirstName",
          sortable: true,
        },
        {
          text: "Имя",
          value: "LastName",
          sortable: false,
        },
        {
          text: "Категория",
          value: "cat",
          sortable: false,
        },
        {
          text: "Причина",
          value: "causesID",
          sortable: true,
        },
        {
          text: "Дата",
          value: "date",
          sortable: true,
        },
      ],
      headersEat: [
        {
          text: "Класс",
          value: "className",
          sortable: true,
          style: "",
        },
      ],
      marks: [],
      marksPrint: [],
      sDates: [],
      classList: [],
      classListOption: [],
      classListAll: [],
      studentsList: [{}],
      countAll: [{}],
      activeClassOK: "class-mark-item-ok",
      activeClassBAD: "class-mark-item-BAD",
      activeClassChange: "class-mark-item-change",
      selectedClassID: "",
      studentsListByClassID: [{}],
      selectedStudentID: "",
      progress: 0,
      classListCount: [],
      ErrorTableEat: { check: false, count: 0, countTable: 0 },
      missingCount: {
        FirstShift: 0,
        SecondShift: 0,
        FirstShiftAll: 0,
        SecondShiftAll: 0,
      },
    };
  },

  methods: {
    showStatVisualF() {
      this.showStatVisual = !this.showStatVisual;
      //  this.$refs.StatVisual.visualData();
    },
    readyGlobalReportFunc(data) {
      this.readyGlobalReport = data;
    },
    async initialization() {
      this.progress = 0;
      await this.clearFullData();
      await this.getAllClass(); //get классов в classList
      await this.findAllStudents(); // получить всех учеников (students)
      await this.retrieveClass();
      await this.getMarks(); // получить все марки (marks and marksPrint)
      await this.getFullNameStudents();
      await this.defineTileColorClass(); //покрасить плитки в нужный цвет
      await this.countStat();
      this.changeGlobalOption(this.globalOption);
    },

    pickTable() {
      let target = document.getElementById("table");
      let rng, sel;
      if (document.createRange) {
        rng = document.createRange();
        rng.selectNode(target);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(rng);
      } else {
        rng = document.body.createTextRange();
        rng.moveToElementText(target);
        rng.select();
      }

      document.execCommand("copy");
    },
    retrieveClass() {
      if (this.selectedClass.classID != "admin") {
        for (let i = 0; i < this.classList.length; i++) {
          if (this.selectedClass.classID === this.classList[i].classID) {
            this.selectedClassID = this.classList[i];

            this.changeClass();
          }
        }

        document.getElementById("class").disabled = true;
      }
    },

    showModalGeneralSummary() {
      this.showModalGeneralSummaryCheck = !this.showModalGeneralSummaryCheck;
      this.$refs.GeneralSummaryVue.retrieveCategory();
      this.$refs.GeneralSummaryVue.countStat();
    },
    async initializationByRange() {
      this.progress = 0;
      this.clearFullData();

      await this.getAllClass(); //get классов в classList
      await this.getMarksByDateRange(); // получить все марки (marks and marksPrint)
      await this.findAllStudents(); // получить всех учеников (students)
      await this.defineTileColorClass(); //покрасить плитки в нужный цвет
      await this.countStat();
      this.changeGlobalOption(this.globalOption);
      await this.retrieveClass();
      this.progress = 100;
    },
    async initializationByClass() {
      this.progress = 0;
      this.clearFullDataClass();

      this.showStudentsInfo = true;

      await this.changeClass();
      await this.getMarksByDateRange(); // получить все марки (marks and marksPrint)
      // await this.findAllStudents();
      // if (this.selectedStudentID != "") {
      //   await this.changeStudent();
      // }

      await this.countStat();
      await this.getFullNameStudents();
      this.changeGlobalOption(this.globalOption);
      //await this.getAllClass();
      await this.retrieveClass();
      await this.defineTileColorClass(); //покрасить плитки в нужный цвет
      this.progress = 100;
    },

    getClassListCount() {
      this.missingCount.FirstShiftAll = 0;
      this.missingCount.SecondShiftAll = 0;
      this.classListCount = [];
      document.getElementById("classCountTable").hidden =
        !document.getElementById("classCountTable").hidden;
      let tempObj = {};
      for (let i = 0; i < this.classList.length; i++) {
        tempObj = {
          _id: this.classList[i].classID,
          className: this.classList[i].className,
          count: 0,
          female: 0,
          male: 0,
          missing: 0,
          shift: this.classList[i].shift,
        };
        this.classListCount.push(tempObj);
      }

      for (let i = 0; i < this.studentsList.length; i++) {
        for (let j = 0; j < this.classListCount.length; j++) {
          if (this.classListCount[j]._id === this.studentsList[i].classID) {
            this.classListCount[j].count += 1;
            if (this.classListCount[j].shift === "1 смена") {
              this.missingCount.FirstShiftAll += 1;
            }
            if (this.classListCount[j].shift === "2 смена") {
              this.missingCount.SecondShiftAll += 1;
            }
          }
          if (
            this.classListCount[j]._id === this.studentsList[i].classID &&
            (this.studentsList[i].Surname == "М" ||
              this.studentsList[i].Surname == "м")
          ) {
            this.classListCount[j].male += 1;
          }
          if (
            this.classListCount[j]._id === this.studentsList[i].classID &&
            this.studentsList[i].Surname == "Ж"
          ) {
            this.classListCount[j].female += 1;
          }
        }
      }

      this.missingCount.FirstShift = 0;
      this.missingCount.SecondShift = 0;

      for (let i = 0; i < this.classListCount.length; i++) {
        for (let j = 0; j < this.marksPrint.length; j++) {
          if (this.classListCount[i]._id === this.marksPrint[j].classID) {
            this.classListCount[i].missing++;
            if (this.classListCount[i].shift === "1 смена") {
              this.missingCount.FirstShift += 1;
            }
            if (this.classListCount[i].shift === "2 смена") {
              this.missingCount.SecondShift += 1;
            }
          }
        }
      }
    },

    changeStudent() {
      if (this.selectedStudentID != "") {
        this.studentsList = [];
        this.studentsList.push(this.selectedStudentID);
      }
    },
    clearFullData() {
      this.classListAll = [];
      this.classList = [];
      this.studentsList = [];
      this.selectedClassID = "";
      this.studentsListByClassID = [{}];
      this.selectedStudentID = "";
      this.marks = [];
      this.students = [];
      this.marksPrint = [];
      this.countAll = [{}];
      this.showStudentsInfo = false;
    },

    clearFullDataClass() {
      //this.studentsList = [];
      //this.studentsListByClassID = [{}];
      this.marks = [];
      //this.students = [];
      this.marksPrint = [];
      this.countAll = [{}];
    },

    changeClass() {
      this.studentsListByClassID = [{}];

      this.changeGlobalOption(this.globalOption);
      let newStudentList = this.studentsList.filter((elem) => {
        if (elem.classID === this.selectedClassID.classID) return elem;
      });
      for (let i = 0; i < newStudentList.length; i++) {
        this.studentsListByClassID.push(newStudentList[i]);
      }
      if (
        this.globalOption === "Питание" ||
        this.globalOption === "Питание 1 смена" ||
        this.globalOption === "Питание 2 смена"
      ) {
        this.classList = this.classList.filter(
          (ID) => ID === this.selectedClassID,
        );
      }
    },

    async getAllClass() {
      //получить список всех классов (classList)
      await TutorialDataService.getAllCLass()
        .then((response) => {
          this.classList = response.data.map(this.getDisplayClass);
          this.classListOption = response.data.map(this.getDisplayClass);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getMarks() {
      let classID = this.selectedClassID;
      if (classID.length === 0) {
        classID = null;
      }

      await TutorialDataService.findMarks({
        classID: classID,
        date: this.sDates.date,
      })
        .then((response) => {
          let temp = Object.values(response.data);

          for (let i = 0; i < temp.length; i++) {
            temp[i].date = new Date(temp[i].date).toLocaleDateString();
          }
          this.getMarksToPrint(Object.values(temp));
          this.marks = Object.values(temp);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getMarksByDateRange() {
      let classID = this.selectedClassID;
      let studentID = null;

      if (classID.length === 0) {
        classID = null;
      }

      if (this.selectedStudentID != "") {
        studentID = this.selectedStudentID._id;
      }

      await TutorialDataService.findMarksByDateRange({
        classID: classID,
        dateFrom: this.dateFrom,
        dateBefore: this.dateBefore,
        studentID: studentID,
      })
        .then((response) => {
          let temp = Object.values(response.data);

          for (let i = 0; i < temp.length; i++) {
            temp[i].date = new Date(temp[i].date).toLocaleDateString();
          }
          this.getMarksToPrint(Object.values(temp));
          this.marks = Object.values(temp);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getMarksToPrint(data) {
      data.map((mark) => {
        if (mark.causesID != "" && mark.causesID != "Питался") {
          this.marksPrint.push(mark);
        }
      });
    },
    async findAllStudents() {
      await TutorialDataService.findStudentByClassID().then((response) => {
        let students = Object.values(response.data);
        students.map((student) => {
          if (student != undefined) {
            this.studentsList.push(student);
          }
        });
      });
      this.getFullNameStudents();
    },

    defineTileColorClass() {
      for (let i = 0; i < this.classList.length; i++) {
        this.$set(this.classList[i], "sumMark", 0);
        this.$set(this.classList[i], "sumStudent", 0);
        this.$set(this.classList[i], "sumPortionFood", 0);
        for (let j = 0; j < this.studentsList.length; j++) {
          if (this.studentsList[j].classID === this.classList[i].classID) {
            this.classList[i].sumStudent += 1;
          }
        }
        for (let p = 0; p < this.marks.length; p++) {
          if (this.marks[p].classID === this.classList[i].classID) {
            if (this.marks[p].countEating >= 1) {
              this.classList[i].sumPortionFood += this.marks[p].countEating;
            }
            this.$set(this.marks[p], "className", this.classList[i].className);
            this.$set(this.classList[i], "created", true);
            this.classList[i].sumMark += 1;
            if (this.marks[p].updatedAt !== this.marks[p].createdAt) {
              this.$set(this.classList[i], "change", true);
            }
          }
        }
      }
    },

    help() {
      console.log("Data", this.sDates.date);
      console.log("this.marks", this.marks);
      console.log("this.marksPrint", this.marksPrint);
      console.log("this.classList", this.classList);
      console.log("this.classListAll", this.classListAll);
      console.log("this.studentsList", this.studentsList);
      console.log("this.marks", this.marks);
      console.log("this.countAll", this.countAll);
      alert(
        "Красная - еще не подали \nСиняя - подали, но изменили в течении дня \nБирюзовая - подали, но нет порций питания\nЖелто-зеленая - есть разница между поданными сведениями и детьми в классе\nЗеленые - подали без изменения \nПроценты считаются от количества учеников, на которых подали данные (Данные отправлены на: Х)",
      );
    },
    async getFullNameStudents() {
      this.progress = 0;
      // поместить в массив  marksPrint имена и фамилии учеников
      for (let i = 0; i < this.studentsList.length; i++) {
        for (let j = 0; j < this.marksPrint.length; j++) {
          if (this.marksPrint[j].studentID === this.studentsList[i]._id) {
            this.$set(
              this.marksPrint[j],
              "FirstName",
              this.studentsList[i].FirstName,
            );
            this.$set(
              this.marksPrint[j],
              "LastName",
              this.studentsList[i].LastName,
            );
          }
        }
        // this.progress = Math.round((i / this.studentsList.length) * 100);
      }

      return;
    },

    async countStat() {
      let set = new Set();
      let arr = [];
      let obj = {};
      for (let i = 0; i < this.marks.length; i++) {
        set.add(this.marks[i].causesID);
      }
      i = 0;
      for (let value of set) {
        obj = { causes: value, count: 0 };

        this.countAll[i] = obj;
        if (value === "" && i != 0) {
          let temp = this.countAll[0];
          this.countAll[0] = this.countAll[i];
          this.countAll[i] = temp;
        } else {
          if (this.countAll[0].causes === "") {
            if (value === "Питался" && i != 1) {
              let temp = this.countAll[1];
              this.countAll[1] = this.countAll[i];
              this.countAll[i] = temp;
            } else {
              this.countAll[i] = obj;
            }
          } else {
            if (value === "Питался" && i != 0) {
              let temp = this.countAll[0];
              this.countAll[0] = this.countAll[i];
              this.countAll[i] = temp;
            } else {
              this.countAll[i] = obj;
            }
          }
        }
        arr[i] = obj;
        i += 1;
      }
      for (i = 0; i < this.marks.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (this.marks[i].causesID === arr[j].causes) {
            arr[j].count++;
          }
        }
      }

      let i; // temp;
      for (let j = 0; j < arr.length; j++) {
        if (arr[j].causes === "") {
          arr[j].causes = "Не питались";
          // temp = j;
        }
        if (arr[j].causes === "Питался") {
          i = j;
        }
      }

      this.$set(this.countAll, arr);
      this.progress = 100;
    },

    retrieveData() {
      let date = new Date();
      date.setHours(date.getHours() + 3);
      this.$set(this.sDates, "date", new Date(date).toISOString().slice(0, 10));
      this.dateFrom = new Date().toISOString().slice(0, 10);
      this.dateBefore = new Date().toISOString().slice(0, 10);
    },

    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
        shift: data.shift,
        change: false,
        count: 0,
        classLider: data.classLider,
        level: data.level,
      };
    },

    // Метод печати
    printStat() {
      //let dataReportBox = document.getElementById("table"); // Получаем узел содержимого для печати
      // Берем узел, который не отображается, можно скрыть или удалить узел
      // let top = document.getElementsByClassName("top")[0];
      // dataReportBox.removeChild(top);

      // Создаем нужные вам узлы
      //
      //$("# dataReportBox").prepend(topdiv); // Вставляем вновь созданный узел div в верхнюю часть содержимого контейнера dataReportBox
      let data = this.sDates.date;

      let auth = localStorage.getItem("user");
      if (this.globalOption === "Отсутвующие") {
        // Получаем ссылку на элемент в который мы хотим добавить новый элемент ul-stat
        let top =
          "<h2> МБОУ СОШ №24</h2> <h3>Отсутствующие на " + data + "</h3>";
        let printHtml = document.getElementById("table").innerHTML; // Получаем содержимое узла для печати
        let printHtml2 = document.getElementById("ul-stat").innerHTML;

        printHtml2 += printHtml;
        top += printHtml2;

        let tab = window.open("");
        tab.document.write(top);
        // tab.document.close();
        //window.location.href=url;

        // window.document.body.innerHTML = top; // Присваиваем напечатанное содержимое содержимому страницы

        tab.print(); // Вызов метода печати

        localStorage.setItem("user", auth);
        // window.location.reload(); // Страница перезагружается после печати
        this.$router.push({ name: "Statistic" });
      } else {
        // Получаем ссылку на элемент в который мы хотим добавить новый элемент ul-stat
        let top = "<h2> МБОУ СОШ №24</h2> <h3>Питание на " + data + "</h3>";
        let printHtml = document.getElementById("table").innerHTML; // Получаем содержимое узла для печати
        let printHtml2 = document.getElementById("ul-stat").innerHTML;

        printHtml2 += printHtml;
        top += printHtml2;

        let tab = window.open("");
        tab.document.write(top);

        //  window.document.body.innerHTML = top; // Присваиваем напечатанное содержимое содержимому страницы

        //window.print(); // Вызов метода печати
        //window.location.reload(); // Страница перезагружается после печати
        //  this.$router.push({ name: "Dashboard" });
        localStorage.setItem("user", auth);
        this.$router.push({ name: "Statistic" });
      }
    },

    newPrintStat() {
      let auth = localStorage.getItem("user");
      let data = this.sDates.date;
      let printHtml2 = "";

      let top =
        "<head>" +
        "</head><body onload='window.print()'><h2> МБОУ СОШ №24</h2> <h3>Отсутствующие на " +
        data +
        "</h3>";
      let printHtml = document.getElementById("table").innerHTML; // Получаем содержимое узла для печати

      printHtml2 = document.getElementById("ul-stat").innerHTML;

      let style =
        "<style type='text/css'>.LI-class {color: black;} ul,li {color: black;}table {border: 1px solid black; border-collapse: collapse; font-size:10px}  th,td { padding: 2px;border: 1px solid black; } </style> ";

      let ulstat3 = document.getElementById("ul-stat3").innerHTML;

      printHtml2 += ulstat3;
      printHtml2 += printHtml;
      top += printHtml2;
      top += style;

      top +=
        "<footer><h3>Директор МБОУ СОШ №24 &nbsp &nbsp&nbsp&nbsp&nbsp_________ &nbsp&nbsp&nbsp&nbsp&nbsp&nbspН.А. Голеницкая</h3></footer>";

      if (this.selectedClassID != "") {
        top +=
          "<h3>Классный руководитель &nbsp &nbsp&nbsp&nbsp&nbsp_________ &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" +
          this.selectedClassID.classLider +
          "</h3>";
      }

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0",
      );
      tab.document.write(top);
      // tab.document.close();
      tab.focus();
      localStorage.setItem("user", auth);

      //  tab.print();

      // tab.onfocus = function () {
      //   setTimeout(function () {
      //     tab.close();
      //   }, 500);
      // };
    },

    async changeGlobalOption(globalOption) {
      if (globalOption != "start") {
        //clear class print
        this.classListAll = [];
        let arrayCat = [];

        //добавить столбец в таблицу (всего)
        this.$set(this.headersEat, 1, {
          text: "Всего",
          value: "count",
          sortable: false,
        });

        //получить категории
        await this.getAllCategory();
        //добавить столбцы категорий
        for (let i = 0; i < this.sCategory.length; i++) {
          this.$set(this.headersEat, i + 2, {
            text: this.sCategory[i].sCategory,
            value: this.sCategory[i].id,

            sortable: false,
          });
        }

        this.$set(this.headersEat, this.sCategory.length + 2, {
          text: "Без категории",
          value: "countEmptyCat",
          sortable: false,
        });

        //сформировать список классов согласно выбору
        if (globalOption === "Питание") {
          this.classListAll = [];
          for (let j = 0; j < this.classList.length; j++) {
            this.classListAll.push(this.classList[j]);
          }
        }
        if (globalOption === "Питание 1 смена") {
          this.classListAll = [];
          for (let j = 0; j < this.classList.length; j++) {
            if (this.classList[j].shift === "1 смена") {
              this.classListAll.push(this.classList[j]);
            }
          }
        }
        if (globalOption === "Питание 2 смена") {
          this.classListAll = [];
          for (let j = 0; j < this.classList.length; j++) {
            if (this.classList[j].shift === "2 смена")
              this.classListAll.push(this.classList[j]);
          }
        }

        //добавить в переменную чистый параметр для подсчета общей суммы
        for (let j = 0; j < this.classListAll.length; j++) {
          this.$set(this.classListAll[j], "count", 0);
          for (let i = 0; i < this.sCategory.length; i++) {
            this.$set(this.classListAll[j], this.sCategory[i].id, 0);
            this.$set(this.classListAll[j], "countEmptyCat", 0); //пустые категории
            arrayCat[i] = 0;
          }
        }

        //добавить последнюю строку с ИТОГО
        if (
          this.classListAll[this.classListAll.length - 1].className != "Итого"
        )
          this.classListAll.push({ className: "Итого", count: 0 });

        //посчитать количество питающихся для классов всего + cat
        let temp = 0;

        //очистка "Всего"
        for (let j = 0; j < this.classListAll.length - 1; j++) {
          this.$set(this.classListAll[j], "count", 0);
        }

        for (let j = 0; j < this.classListAll.length - 1; j++) {
          for (let i = 0; i < this.sCategory.length; i++) {
            arrayCat[i] = 0;
          }
          for (let y = 0; y < this.marks.length; y++) {
            if (
              this.marks[y].causesID === "Питался" &&
              this.marks[y].classID === this.classListAll[j].classID
            ) {
              this.$set(
                this.classListAll[j],
                "count",
                this.classListAll[j].count + this.marks[y].countEating,
              );
              if (this.marks[y].cat === "") {
                this.$set(
                  this.classListAll[j],
                  "countEmptyCat",
                  this.classListAll[j].countEmptyCat +
                    this.marks[y].countEating,
                );
              }

              // let countCat = this.sCategory[i].sCategory;
              for (let i = 0; i < this.sCategory.length; i++) {
                if (this.marks[y].cat === this.sCategory[i].sCategory) {
                  arrayCat[i] = arrayCat[i] + this.marks[y].countEating;

                  this.$set(
                    this.classListAll[j],
                    this.sCategory[i].id,
                    arrayCat[i],
                  );
                  this.$set(
                    this.sCategory[i],
                    "count",
                    this.sCategory[i].count + this.marks[y].countEating,
                  );
                }
              }

              temp += this.marks[y].countEating;
            }
          }
        }

        this.$set(
          this.classListAll[this.classListAll.length - 1],
          "count",
          temp,
        );

        //проверка на ошибку
        this.checkErrorTableEat(temp);

        for (let i = 0; i < this.sCategory.length; i++) {
          this.$set(
            this.classListAll[this.classListAll.length - 1],
            this.sCategory[i].id,
            this.sCategory[i].count,
          );
        }
      }
    },

    checkErrorTableEat(temp) {
      for (let i = 0; i < this.countAll.length; i++) {
        if (this.countAll[i].causes === "Питался") {
          if (temp > this.countAll[i].count) {
            this.ErrorTableEat.check = true;
            this.ErrorTableEat.count = this.countAll[i].count;
            this.ErrorTableEat.countTable = temp;
          } else {
            this.ErrorTableEatcheck = false;
            this.ErrorTableEat.count = this.countAll[i].count;
            this.ErrorTableEat.countTable = temp;
          }
        }
      }
    },
    async getAllCategory() {
      await TutorialDataService.getCategory()
        .then((response) => {
          this.sCategory = response.data.map(this.getDispleyCategory);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getDispleyCategory(data) {
      return {
        sCategory: data.cat,
        id: data._id,
        count: 0,
      };
    },

    reversedMessage: function (data) {
      let a = (data / this.marks.length) * 100;

      return a.toFixed(2);
    },
  },
  mounted() {
    this.initialization();
    this.retrieveData();
  },
};
</script>

<style scoped>
@import "../assets/style.css";
.shift-name {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.main-stat {
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: rgb(238, 252, 252);
  height: 100%;
}
.class-marks {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin: 1px;
  justify-content: center;
  column-gap: none;
}

.class-mark {
  margin: 5px;
  text-align: center;
  width: 360px;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  /* border-radius: 3px; */
  position: relative;
  background-color: white;
  box-shadow: 10px 10px 14px rgba(34, 0, 255, 0.25),
    0 10px 10px rgba(2, 0, 55, 0.22);
  /* box-shadow: 0 0 3px #4d4d4d; */

  margin: 4px 4px 4px 4px;
}

.class-mark-noBoard {
  text-align: center;
  width: 380px;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;

  margin: 1px 1px 1px 1px;
}

.class-mark-shift {
  text-align: center;
  width: 350px;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
}

.class-mark-change {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 330px;
}
.class-mark-change div {
  padding: 2px;
}
.class-mark-item-text {
  background-color: green;
  margin: 1px;
  color: white;
  padding: 5px 40px 5px 3px;
}
.class-mark-item-ok {
  background-color: green;
  margin: 1px;
  color: white;
  padding: 5px 30px 5px 3px;
  max-width: 15px;
  text-align: center;
}
.class-mark-item-ok:hover {
  background-color: rgb(0, 88, 0);
}
.class-mark-item-change {
  background-color: rgb(0, 55, 128);
  color: white;
  margin: 1px;
  padding: 5px 30px 5px 3px;
  max-width: 15px;
  text-align: center;
}
.class-mark-item-change:hover {
  background-color: rgb(0, 37, 86);
}
.class-mark-item-differ {
  background-color: rgb(169, 155, 0);
  color: white;
  margin: 1px;
  padding: 5px 30px 5px 3px;
  max-width: 15px;
  text-align: center;
}
.class-mark-item-differ:hover {
  background-color: rgb(104, 95, 0);
}
.class-mark-item-noPortion {
  background-color: rgb(0, 135, 124);
  color: white;
  margin: 1px;
  padding: 5px 30px 5px 3px;
  max-width: 15px;
  text-align: center;
}
.class-mark-item-noPortion:hover {
  background-color: rgb(0, 104, 81);
}
.class-mark-item-BAD {
  background-color: rgb(128, 0, 0);
  color: white;
  margin: 1px;
  padding: 5px 30px 5px 3px;
  max-width: 15px;
  text-align: center;
}
.class-mark-item-BAD:hover {
  background-color: rgb(77, 0, 0);
}

.v-data-table td {
  border-bottom: none !important;
  height: 2px !important;
}

.v-data-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.search-table label {
  font-size: 10px;
}

.table th,
td {
  border: 1px solid rgb(0, 0, 0);
  height: 10px;
  padding: 2px;
}
</style>
